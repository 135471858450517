<template>
  <div>
    <el-row
      style="
        margin-bottom: 20px;
        display: flex;
        align-item: center;
        justify-content: space-between;
      "
    >
      <el-col :span="24">
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1"> 管理联盟机构 </el-menu-item>
          <el-menu-item index="2"> 机构审批 </el-menu-item>
        </el-menu>
      </el-col>
      <!-- <el-col
        :span="4"
        style="display: flex; align-item: center; justify-content: flex-end"
      >
        <Button
          text="新增购买"
          type="blue"
        />
      </el-col> -->
    </el-row>
    <div class="node-body">
      <div
        style="margin-bottom: 20px; display: flex; justify-content: flex-end"
      >
        <Button text="邀请机构" type="blue" @click="submit('scope.row')" />
      </div>
      <el-table :data="tableData" border>
        <el-table-column prop="name" label="机构名称" min-width="30%">
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="20%">
        </el-table-column>
        <el-table-column prop="time" label="加入时间" min-width="25%">
        </el-table-column>
        <el-table-column prop="operation" label="操作" min-width="25%">
        </el-table-column>
      </el-table>
    </div>
    <div class="bottom">
      <Pagination />
    </div>

    <Dialog
      :visible="dialogVisible"
      :title="'邀请机构'"
      @dialogClose="dialogVisible = false"
      :size="'mini'"
    >
      <el-form
        ref="form"
        :model="formData"
        label-width="auto"
        label-position="left"
      >
        <el-form-item label="">
          <div style="margin-bottom: 20px">*联系方式</div>
          <el-input v-model="formData.contact"></el-input>
        </el-form-item>
      </el-form>
      <span style="display: flex; justify-content: flex-end">
        <Button type="white" @click="dialogVisible = false" text="发送邀请" />
      </span>
    </Dialog>
  </div>
</template>

<script>
import { Pagination, Button, Dialog } from '@/components/common/Index';

export default {
  components: {
    Pagination,
    Button,
    Dialog,
  },
  data() {
    return {
      active: 1,
      activeIndex: '1',
      tableData: [
        {
          name: '张三',
          status: '正常',
          linkedchain: '5345链',
          time: '2020-09-22',
          operation: '',
        },
      ],
      dialogVisible: false,
      formData: {
        contact: '',
      },
    };
  },
  methods: {
    handleSelect(key) {
      this.active = key;
    },
    submit(row) {
      this.dialogVisible = true;
      console.log(row, this.dialogVisible);
    },
  },
};
</script>

<style scoped>
.node-body {
  flex: 1;
}
.bottom {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
